<template>
  <div
    class="threejs-container"
    v-loading="loading"
    element-loading-text="拼命加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  ></div>
</template>

<script>
// import { nextTick } from 'vue/types/umd';
import ThreeBase from './three-base.js';

export default {
  props: {
    // 场景纹理url
    sceneUrl: {
      type: String,
      required: true,
    },
    // 模型url
    modelUrl: {
      type: String,
    },
    // 是否自动旋转
    autoRotate: {
      type: Boolean,
      default: false,
    },
    // 生成的canvas是否铺满浏览器
    isFullBrowser: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      threeView: null,
      loading: true,
    };
  },
  watch: {
    sceneUrl(val) {
      this.threeView.loadScene(val);
      this.loading = false;
    },
    modelUrl(val) {
      this.threeView.loadModel(val);
      this.loading = false;
    },
    autoRotate(val) {
      this.threeView.controlsRotate(val);
      this.loading = false;
    },
    deep: true,
  },
  mounted() {
    this.threeView = new ThreeBase(this.$el, {
      sceneUrl: this.sceneUrl,
      modelUrl: this.modelUrl,
      autoRotate: this.autoRotate,
      isFullBrowser: this.isFullBrowser,
    });
    this.loading = false;
  },
};
</script>

<style lang="scss">
.threejs-container {
  width: 100%;
  height: 100%;
  cursor: grab;
}
</style>
