<template>
  <article class="selector-wrap">
    <section class="selector">
      <main class="selector-main">
        <button
          v-for="category in categoryData"
          :key="category.id"
          :class="activeId === category.id ? 'btn active' : 'btn'"
          @click="clickItem('item', category)"
        >
          {{ category.name }}
        </button>
        <template v-for="category in categoryData">
          <CategoryCom
            v-if="category.children && category.children.length && showNodes(category.id)"
            :key="category.id + 1000"
            :category-data="category.children"
            :events="events"
          ></CategoryCom>
        </template>
      </main>
    </section>
  </article>
</template>

<script>
export default {
  name: 'CategoryCom',
  components: {},
  mixins: [],
  props: {
    categoryData: {
      type: Array,
      require: true,
      default: () => [],
    },
    active: {
      type: Number,
      default: -1,
    },
    events: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeId: 0, // 默认一级全选
    };
  },
  computed: {
    allClass() {
      return {
        btn: true,
        active: this.activeId === 0,
      };
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.initBtnsStatus();
  },
  methods: {
    // 初始化默认选中的按钮
    initBtnsStatus() {
      /**
       * 判断 props 里面所需要的字段是否为空
       * 1：为空不做任何处理
       * 如果不为空，判断是否有子节点，如果有当前选中的按钮就位此节点
       *
       */
      if (this.categoryData.length) {
        if (this.categoryData[0].children) {
          this.activeId = this.categoryData[0].id;
        } else {
          let category = { ...this.categoryData[0] };
          this.activeId = category.id;
          // 默认事件
          this.events.selectAllEvent && this.events.selectAllEvent(category);
        }
      }
    },
    // 点击分类事件
    clickItem(type, data) {
      // 判断上次点击的按钮是否跟当前的一样，一样的话不做后续的操作
      if (this.activeId === data.id) {
        return false;
      }
      this.activeId = data.id;
      if (type === 'all') {
        this.events.selectAllEvent && this.events.selectAllEvent(data);
      } else {
        this.events.selectSingleEvent && this.events.selectSingleEvent(data);
      }
    },
    showNodes(id) {
      return this.activeId === id;
    },
  },
};
</script>

<style lang="scss" scoped>
$font-size: 12px;

.selector {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  &-wrap {
    // position: absolute;
    // left: 0;
    float: left;
    display: block;
    width: calc(100% - 1em);
    // padding: 0.5em;
    font-size: $font-size;
    background-color: #efefef;
    user-select: none;
  }

  &-aside {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 1em;
    padding-top: 0.5em;
    box-sizing: border-box;
  }

  &-main {
    flex: 1;
    // padding-left: 1em;
    padding-top: 0.5em;
    box-sizing: border-box;

    & > .btn {
      margin-right: 1em;
      margin-bottom: 0.5em;
    }
  }
}

.btn {
  display: inline-block;
  padding: 0.2em 0.8em;
  vertical-align: middle;
  color: #29303e;
  font-weight: 400;
  font-size: $font-size;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #29303e;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: #fff;
    background-color: #29303e;
    border-color: #29303e;
  }
}
</style>
