import $http from '@/utils/http';

// 获取模型配置文件
export function modelsConfig_list(data) {
  // return $http.get('/api/modelsConfig_list', data, 'false', 'false');
  return $http.get('/v2/model_config/index', data, 'false', 'false');
}

// 获取配置列表
export function getconfiglist(data) {
  // return $http.post('/api/modelssystemconfig/getconfiglist', data, 'false');
  return $http.post('/v2/models_system_config/index', data, 'false');
}

// 贴图 - 列表
export function getmappinglist(data) {
  // return $http.get('/api/modelsmapping/getmappinglist', data, 'false');
  return $http.get('/v2/model_mapping/index', data, 'false');
}

// 贴图 - 添加修改
export function setmappingsave(data) {
  // return $http.post('/api/modelsmapping/setmappingsave', data, 'false', 'multipart/form-data');
  return $http.post('/v2/model_mapping/save', data, 'false', 'multipart/form-data');
}

export function setmappingsaveitem(data) {
  // return $http.post('/api/modelsmapping/setmappingsave', data, 'false', 'multipart/form-data');
  return $http.post('/v2/model_mapping/save_item', data, 'false', 'multipart/form-data');
}

// 贴图 - 删除
export function setmappingdel(data) {
  // return $http.post('/api/modelsmapping/setmappingdel', data, 'false')
  return $http.post('/v2/model_mapping/delete', data, 'false');
}

// 模型列表
export function model_baseIndex(data) {
  // return $http.get('/api/model_base/index', data, 'false');
  return $http.get('/v2/newest_model/index', data, 'false');
}

// 模型详情
export function model_baseModel_details(data) {
  // return $http.get('/api/model_base/model_details', data, 'false');
  return $http.get('/v2/newest_model/details', data, 'false');
}

//添加模型基础信息
export function model_baseStep1(data) {
  // return $http.post('/api/model_base/step1', data, 'false');
  return $http.post('/v2/newest_model/step1', data, 'false');
}

//添加模型基础信息
export function model_baseEdit_step1(data) {
  // return $http.post('/api/model_base/edit_step1', data, 'false');
  return $http.post('/v2/newest_model/step1', data, 'false');
}

// 模型文件上传
export function modelsFileUpload(data) {
  // return $http.post('/api/modelsFileUpload', data, 'false', 'multipart/form-data');
  return $http.post('/v2/newest_model/file_upload', data, 'false', 'multipart/form-data');
}

//添加/编辑备注信息
export function model_baseStep4(data) {
  // return $http.post('/api/model_base/step4', data, 'false');
  return $http.post('/v2/newest_model/step4', data, 'false');
}

//添加/编辑备注信息
export function delete_model(data) {
  // return $http.post('/api/model_base/delete_model', data, 'false');
  return $http.post('/v2/newest_model/delete', data, 'false');
}

//新增模型审核
export function newAdd_audit(data) {
  // return $http.post('/api/newAdd_audit', data, 'false');
  return $http.post('/v2/newest_model/add_audit', data, 'false');
}

//删除模型文件
export function del_modelFile(data) {
  // return $http.post('/api/del_modelFile', data, 'false');
  return $http.post('/v2/newest_model/file_delete', data, 'false');
}

//添加/编辑规格信息
export function step2(data) {
  // return $http.post('/api/model_base/step2', data, 'false');
  return $http.post('/v2/newest_model/step2', data, 'false');
}

//添加/编辑规格信息
export function model_setstatus(data) {
  return $http.post('/api/model_base/model_setstatus', data, 'false');
}
//贴图 - 修改分类
export function setclassifysave(data) {
  // return $http.post('/api/modelsmapping/setclassifysave', data, 'false');
  return $http.post('/v2/model_mapping/set_classify', data, 'false');
}
//模型 - 修改分类
export function model_setclassify(data) {
  // return $http.post('/api/model_base/model_setclassify', data, 'false');
  return $http.post('/v2/newest_model/set_classify', data, 'false');
}
